@import '../../assets/css/variables.scss';

.pagination {
  display: flex;
  align-self: center;
  justify-self: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.page-button {
  background-color: transparent;
  color: $text-color;
  border: solid 2px $border-color;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $hover-color;
  }

  &.active {
    color: $primary-color;
    background-color: $hover-color;
    cursor: auto;
  }
}
