@import '../../assets/css/variables.scss';

.loader {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: loading infinite linear 1s;
    
    circle {
      fill: $primary-color;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-dark;
}

@keyframes loading {
  0%, 80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }
}
