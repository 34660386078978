@import '../../assets/css/variables.scss';

.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 30px);

    &_field {
        width: 100%;
        align-self: center;
        justify-self: center;
        background-color: $background-mid;
        border: none;
        padding: 5px 15px 5px 15px;
        border: solid 2px $border-color;
        border-radius: 5px 0 0 5px;

        &:focus {
            outline: none;
            border: solid 2px $primary-color;
        }
    }

    &_button {
        height: 100%;
        background-color: $border-color;
        border: none;
        border-radius: 0 5px 5px 0;
        padding: 5px 15px;
        cursor: pointer;
    }

    &:focus-within .search-bar_button {
        background-color: $primary-color;
    }
}