$primary-color: #6db33f;
$border-color: #666;

$background-dark: #222;
$background-mid: #282828;
$background-light: #333;

$hover-color: #555;

$text-color: #eee;
$muted-text-color: #777;

$sticky-background: linear-gradient(180deg, #222222 0%, #222222d5 75%, transparent 100%);

$nutriscore-colors: (
  a: #037E3C,
  b: #8BC61E,
  c: #FECE00,
  d: #FF8F02,
  e: #FF3B0A
);