@import './variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-dark;
}

::selection {
  background-color: $primary-color;
}

::-webkit-scrollbar {
  background-color: transparent;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: $background-mid;
  border: solid 2px $border-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: $background-light;
  border: solid 2px $border-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $primary-color;
}